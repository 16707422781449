import React from 'react';
// import RightImg from "../../assets/img/screen-two/screen-two-1.png";
import "../../components/componant.css";

function Banner(){
    return(
        <div className="mt-5">
            <div className="container px-0 banner-screenSix-container">
                <div className='top-banner-six'>
                    <div className="row align-items-center mx-0">
                        <div className="col-md-9 col-lg-8" >
                            <h1 className='title-color text-white'>Unlock Your Potential at Yethi, Where Innovation Meets Passion </h1>
                            <p className='desc-color text-white' style={{fontSize:'16px'}}>Join Us in Shaping Tomorrow's Technological Landscape</p>
                        </div>

                        <div className="col-md-3 col-lg-4 text-md-end mt-sm-25">
                            {/* <img src={RightImg} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Banner;