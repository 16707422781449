
import { React, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "../componant.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/popup/logo-1.png";
import { CleaningServices } from "@mui/icons-material";

const  DownloadPopup = ({ isOpen, onClose, url, setIsPopupOpen, title }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    await axios
      .post(
        "https://api.web3forms.com/submit",
        {
          Title: title,
          name: name,
          email: email,
          access_key: "43e98fc7-21fd-4922-99eb-ab0ad2ff95c2",
          subject: `Gate Form Is Submitted`,
          from_name: "Yethi",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {

          event.target.reset();

          navigate("/thankyouDownload");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(url);
    window.open(`${url}`, "_blank");
    onClose();
  };

  // trigger={<button className='butn' style={{padding:'3px 10px', fontSize:'16px'}} >{props.btnTitle}</button>}
  return (
    // <Popup className='popupModal' position="center" open={isOpen} onClose={() => setIsPopupOpen(false)} modal>
    //     <div>
    //         <h5 className='title-color mb-4 text-center'>
    //             Get Resources
    //         </h5>
    //     </div>

    //     <form onSubmit={ handleSubmit } >
    //         <div className="mb-3">
    //             <input type="text" name="name" className="form-control" placeholder='Name' required onChange={e => setName(e.target.value)} />
    //         </div>

    //         <div className="mb-3">
    //             <input type="email" name="email" className="form-control" placeholder='Official Email' required onChange={e => setEmail(e.target.value)} />
    //         </div>

    //         <div className="mt-5 text-center">
    //             <button type="submit"  className='butn' style={{padding:'8px 30px'}}>Submit</button>
    //         </div>
    //     </form>
    //     <span style={{color:'green', fontSize:'20px'}}>{successMessage}</span>
    // </Popup>

    <Popup
      className="popupModal gateFormPopup"
      position="center"
      open={isOpen}
      onClose={() => setIsPopupOpen(false)}
      modal
      overlayStyle={{
        background: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(2px)",
      }}
    >
      {(close) => (
        <div className="gateFormModal">
          <div className="header">
            <img src={logo} alt="Yethi Logo" className="logo" />
          </div>

          <div className="content">
            <h2>
              Get access to our latest guides, whitepapers, and expert insights!
            </h2>

            <form onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="access_key"
                value="43e98fc7-21fd-4922-99eb-ab0ad2ff95c2"
              />

            <input type="hidden" name="subject" value="Gate Form Submission" />

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DownloadPopup;
